/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
window.$ = $;
window.jQuery = $;

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');


function isMobile(){
    var isMobile = false;
    if (navigator.userAgent.match(/iPhone/i)) {
        isMobile = true
    }
    if (navigator.userAgent.match(/iPod/i)) {
        isMobile = true
    }
    var isAndroid = /(android)/i.test(navigator.userAgent)
    var isMobile = /(mobile)/i.test(navigator.userAgent)
    if (isAndroid && isMobile) {
        isMobile = true
    }
    return isMobile;
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}
$( document ).ready(function() {
    //console.log( "ready!" );

    $('#modalRecaptcha').on('show.bs.modal', function (e) {
        $('#modalRecaptcha').css('opacity', 1);
        $('#modalRecaptcha').find('.modal-dialog').css('top', '20%');
        setTimeout(function(){
            $('#modalRecaptcha').find('.modal-backdrop').css('opacity', 0.5);
        }, 100);
    });

    $('#modalRecaptcha').on('hidden.bs.modal', function (e) {
        $('#modalRecaptcha').find('.modal-backdrop').css('opacity', 0);
    });

    if(isMobile()){
        console.log('is mobile');

        //eraseCookie("cookie_mobile_launch");
        var showModal = true;
        if(readCookie("cookie_mobile_launch")){
            if(readCookie("cookie_mobile_launch") == 0){
                showModal = false;
            }
        }

        $('#modalLaunchMobileVersion').on('show.bs.modal', function (e) {
            console.log('event show');
            $('#modalLaunchMobileVersion').css('opacity', 1);
            $('#modalLaunchMobileVersion').find('.modal-dialog').css('top', '20%');
            setTimeout(function(){
                //$('#modalLaunchMobileVersion').find('.modal-backdrop').css('opacity', 0.5);
                $('.modal-backdrop').css('opacity', 0.5);
            }, 100);
        });

        $('#modalLaunchMobileVersion').on('hidden.bs.modal', function (e) {
            $('.modal-backdrop').remove();
            //$('#modalLaunchMobileVersion').find('.modal-backdrop').css('opacity', 0);
            createCookie("cookie_mobile_launch", 0, null);
        });

        if(showModal){
            console.log('show modal mobile');
            $('#modalLaunchMobileVersion').modal('show');
        }else{
            console.log('not show modal mobile');
        }

    }else{
        console.log('not mobile');
    }
    $("#launchMobile").click(function(){
        if (readCookie("cookie_mobile_launch") == null) {
            //eraseCookie("cookie_mobile_launch");
            createCookie("cookie_mobile_launch", 1, null);
        }

        $('#modalLaunchMobileVersion').modal('hide');
        window.location = '/mobile/index.html';
    });
});